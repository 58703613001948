<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>MASTER CUSTOMER</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- <v-row class="mt-2">
      <v-col>
        <v-card outlined>
          <v-card-title>
            Filter Data
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row class="mt-2">
      <v-col>
        <v-card outlined rounded="lg">
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-4">
              <v-btn
                color="success"
                rounded
                @click="
                  (addData.dialog = true),
                    resetForm(),
                    (staUpdate = false),
                    loadJenis(),
                    loadSales()
                "
                v-if="!staPilih"
                ><v-icon class="mr-2">mdi-plus-thick</v-icon>Tambah Data</v-btn
              >
            </div>

            <v-text-field
              name="name"
              label="Pencarian"
              outlined
              dense
              hide-details
              v-model="data.search"
            ></v-text-field>

            <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Customer"
                name="Data Customer.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div>
            <div>
              <v-btn color="primary" text @click="loadData()"
                >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-card outlined rounded="lg">
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    color="primary"
                    text
                    @click="pilihCustomer(item.kode, item.nama, item.jenis)"
                    v-if="staPilih"
                    ><v-icon>mdi-check-bold</v-icon></v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="loadUpdate(item.kode)"
                    v-else
                    ><v-icon>mdi-pen</v-icon></v-btn
                  >
                </template>
                <template v-slot:[`item.alamat2`]="{ item }">
                  {{ item.kecamatan }}, {{ item.kabupaten }},
                  {{ item.provinsi }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addData.dialog"
      width="1100px"
      transition="dialog-transition"
    >
      <v-card class="pb-5">
        <v-card-title
          ><v-icon class="mr-2">mdi-plus-thick</v-icon> Tambah Data
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form v-model="addData.isValid">
            <v-text-field
              v-model="addData.form.kode"
              label="(Otomatis) Kode Customer"
              dense
              outlined
              readonly
              placeholder="Otomatis"
            ></v-text-field>
            <v-text-field
              :rules="addData.rules.nama"
              v-model="addData.form.nama"
              label="Nama"
              dense
              outlined
            ></v-text-field>
            <div class="d-flex justify-space-around">
              <v-text-field
                :rules="addData.rules.person"
                v-model="addData.form.person"
                label="Person"
                dense
                outlined
                class="mr-4"
              ></v-text-field>

              <v-text-field
                :rules="addData.rules.telepon"
                v-model="addData.form.telepon"
                label="Telepon"
                dense
                outlined
              ></v-text-field>
            </div>
            <v-text-field
              :rules="addData.rules.alamat"
              v-model="addData.form.alamat"
              label="Alamat"
              dense
              outlined
            ></v-text-field>
            <div class="d-flex justify-space-around">
              <v-autocomplete
                :loading="wilayahLoading"
                :items="provinsi.items"
                v-model="addData.form.provinsi_id"
                :rules="addData.rules.provinsi_id"
                item-text="name"
                item-value="id"
                label="Provinsi"
                dense
                outlined
                class="mr-2"
                @change="loadKabupaten()"
              ></v-autocomplete>
              <v-autocomplete
                :loading="wilayahLoading"
                :items="kabupaten.items"
                item-text="name"
                v-model="addData.form.kabupaten_id"
                :rules="addData.rules.kabupaten_id"
                item-value="id"
                label="Kabupaten"
                dense
                outlined
                class="mr-2"
                @change="loadKecamatan()"
              ></v-autocomplete>
              <v-autocomplete
                :loading="wilayahLoading"
                :items="kecamatan.items"
                item-text="name"
                v-model="addData.form.kecamatan_id"
                :rules="addData.rules.kecamatan_id"
                item-value="id"
                label="Kecamatan"
                dense
                outlined
              ></v-autocomplete>
            </div>

            <v-text-field
              :rules="addData.rules.kode_outlet"
              v-model="addData.form.kode_outlet"
              label="Kode Outlet"
              dense
              outlined
            ></v-text-field>
            <v-autocomplete
              :items="sales.items"
              v-model="addData.form.sales"
              item-text="email"
              item-value="email"
              label="Sales"
              dense
              outlined
            ></v-autocomplete>
            <v-autocomplete
              :items="jenis.items"
              v-model="addData.form.jenis"
              label="Jenis Customer"
              dense
              outlined
            ></v-autocomplete>

            <v-divider></v-divider>
            <div class="d-flex justify-end mt-2">
              <v-btn
                color="success"
                v-if="!staUpdate"
                :disabled="!addData.isValid"
                @click="createData()"
                >SIMPAN<v-icon>mdi-floppy</v-icon></v-btn
              >
              <v-btn
                v-else
                color="warning"
                :disabled="!addData.isValid"
                @click="updateData()"
                >UPDATE<v-icon>mdi-floppy</v-icon></v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["staPilih"],
  data() {
    return {
      data: {
        headers: [
          { text: "KODE", value: "kode", divider: true },
          { text: "NAMA", value: "nama", divider: true },
          { text: "ALAMAT", value: "alamat", width: "40%", divider: true },
          { text: "ALAMAT2", value: "alamat2", divider: true },
          { text: "PERSON", value: "person", divider: true },
          { text: "TELEPON", value: "telepon", divider: true },
          { text: "KODE OUTLET", value: "kode_outlet", divider: true },
          { text: "JENIS", value: "jenis", divider: true },
          { text: "SALES", value: "sales", divider: true },
          { text: "OPSI", value: "opsi" },
        ],
        items: [],
        loading: false,
        search: "",
      },

      addData: {
        dialog: false,
        isValid: false,
        form: {
          nama: "",
          alamat: "",
          telepon: "",
          person: "",
          kode_outlet: "",
          jenis: "",
          sales: "",
          provinsi: "",
          provinsi_id: "",
          kabupaten: "",
          kabupaten_id: "",
          kecamatan: "",
          kecamatan_id: "",
        },
        rules: {
          nama: [
            (v) => v != "" || "Nama Harus Diisi",
            (v) => v.length > 5 || "Nama Harus Lebih dari 5 Karakter",
          ],
          provinsi_id: [(v) => v != "" || "Nama Harus Diisi"],
          kabupaten_id: [(v) => v != "" || "Nama Harus Diisi"],
          kecamatan_id: [(v) => v != "" || "Nama Harus Diisi"],
          alamat: [
            (v) => v != "" || "Alamat Harus Diisi",
            (v) => v.length > 5 || "Alamat Harus Lebih dari 5 Karakter",
          ],
          telepon: [
            (v) => v != "" || "Telepon Harus Diisi",
            (v) => v.length > 8 || "Telepon Harus Lebih dari 8 Karakter",
          ],
          person: [
            (v) => v != "" || "Person Harus Diisi",
            (v) => v.length > 5 || "Person Harus Lebih dari 5 Karakter",
          ],
        },
        loading: false,
      },
      staUpdate: false,
      wilayahLoading: false,
      provinsi: {
        items: [],
      },
      kabupaten: {
        items: [],
      },
      kecamatan: {
        items: [],
      },
      jenis: {
        items: [],
      },
      sales: {
        items: [],
      },
    };
  },
  mounted() {
    this.loadData();
    this.loadProvinsi();
  },
  methods: {
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("master/customer", {})
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },

    // WILAYAH
    async loadProvinsi() {
      this.wilayahLoading = true;
      const res = await axios.post("master/provinsi");
      this.provinsi.items = res.data.data;
      this.kabupaten.items = [];
      this.kecamatan.items = [];
      this.wilayahLoading = false;
    },
    async loadKabupaten() {
      this.wilayahLoading = true;
      const res = await axios.post("master/kabupaten", {
        provinsi_id: this.addData.form.provinsi_id,
      });
      this.kabupaten.items = res.data.data;
      this.kecamatan.items = [];
      this.wilayahLoading = false;
    },
    async loadKecamatan() {
      this.wilayahLoading = true;
      const res = await axios.post("master/kecamatan", {
        kabupaten_id: this.addData.form.kabupaten_id,
      });
      this.kecamatan.items = res.data.data;
      this.wilayahLoading = false;
    },
    // END WILAYAH

    // JENIS CUSTOMER
    async loadJenis() {
      const res = await axios.post("master/customer/jenis");
      this.jenis.items = res.data.data;
    },
    // END JENIS CUSTOMER

    // SALES
    async loadSales() {
      const res = await axios.post("master/sales");
      this.sales.items = res.data.data;
    },
    // SALES

    async createData() {
      this.addData.loading = true;
      await axios
        .post("master/customer/create", this.addData.form)
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.error, "error");
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
          swal("Gagal", "Ada Kesalahan Server (404)", "error");
        });
      this.addData.loading = false;
    },
    resetForm() {
      this.addData.form = {
        nama: "",
        alamat: "",
        telepon: "",
        person: "",
      };
    },
    async loadUpdate(kode) {
      this.resetForm();
      this.loadJenis();
      this.loadSales();
      this.staUpdate = true;
      await axios
        .post("master/customer/bykode", {
          kode: kode,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.addData.dialog = true;
          this.addData.form = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });
    },
    async updateData() {
      console.log(this.addData.form);
      await axios
        .post("master/customer/update", this.addData.form)
        .then((res) => {
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
          swal("Gagal", "Ada Kesalahan Server (404)", "error");
        });
    },
    async fetchData() {
      const res = await axios.post("master/customer");
      return res.data.data;
    },
    pilihCustomer(kode, nama, jenis) {
      this.$emit("customer", {
        kode: kode,
        nama: nama,
        jenis: jenis,
      });
    },
  },
  computed: {},
};
</script>

<style></style>
