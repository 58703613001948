<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-around">
        <h2>LAPORAN NOTA OUTSTANDING</h2>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="primary" text @click="loadData()"
            >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-1">
      <!-- TOTAL NOTA TAGIHAN -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-text-box</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalTagihan) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Tagihan</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PELUNASAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-plus</v-icon></v-btn
            >
            <div class="text-h5">
              - {{ formatNumber(totalPembayaran) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Pembayaran</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PELUNASAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-search</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalOutstand) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Outstanding</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Awal"
                    :value="formatDate(periode.tanggal1)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal1"
                  @change="loadData()"
                  @input="periode.pickerTanggal1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <!-- <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Akhir"
                    :value="formatDate(periode.tanggal2)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal2"
                  @change="loadData()"
                  @input="periode.pickerTanggal2 = false"
                ></v-date-picker>
              </v-menu>
            </div> -->
            <div>
              <v-text-field
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
                class="mr-2"
              ></v-text-field>
            </div>
            <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn small value="semua"> Semua ({{ countTagihan }}) </v-btn>
                <v-btn small value="lunas"><v-icon small color="success">mdi-check-circle-outline</v-icon> Lunas ({{ countLunas }}) </v-btn>
                <v-btn small value="belum"><v-icon small color="warning">mdi-alert-circle-outline</v-icon>
                  Belum Lunas ({{ countBelumLunas }})
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-spacer></v-spacer>
            <!-- <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Return Penjualan"
                name="Data Return Penjualan.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="filterData"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    @click="loadDetail(item.no_bukti, item.kd_customer)"
                    color="primary"
                    text
                    ><v-icon>mdi-format-list-bulleted</v-icon></v-btn
                  >
                </template>
                <template v-slot:[`item.nama`]="{ item }">
                  <strong>{{ item.nama }}</strong>
                </template>
                <template v-slot:[`item.no_bukti`]="{ item }">
                  <div class="d-flex justify-space-around">
                    <strong>{{ item.no_bukti }}</strong>
                    <v-spacer></v-spacer>
                    <v-icon color="success" v-if="item.rp_outstand <= 0" small
                      >mdi-check-circle-outline</v-icon
                    >
                  </div>
                </template>
                <template v-slot:[`item.tanggal`]="{ item }">
                  {{ formatDate(item.tanggal) }}
                </template>
                <template v-slot:[`item.rp_nota`]="{ item }">
                  {{ formatNumber(item.rp_nota) }}
                </template>
                <template v-slot:[`item.rp_bayar`]="{ item }">
                  {{ formatNumber(item.rp_bayar) }}
                </template>
                <template v-slot:[`item.rp_outstand`]="{ item }">
                  {{ formatNumber(item.rp_outstand) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detail.dialog"
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2">
          <strong>{{ this.data.noBukti }}</strong>
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card outlined>
            <v-data-table
              :headers="detail.headers"
              :items="detail.items"
              :loading="detail.loading"
              :search="detail.search"
              dense
            >
              <template v-slot:[`item.tanggal`]="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:[`item.debit`]="{ item }">
                {{ formatNumber(item.debit) }}
              </template>
              <template v-slot:[`item.kredit`]="{ item }">
                {{ formatNumber(item.kredit) }}
              </template>
              <template v-slot:[`item.saldo`]="{ item }">
                {{ formatNumber(item.saldo) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: ["staPilih"],
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "CUSTOMER", value: "nama", divider: true },
          { text: "REF", value: "no_ref", divider: true },
          { text: "KETERANGAN", value: "keterangan", divider: true },
          { text: "TAGIHAN", value: "rp_nota", divider: true, align: "right" },
          {
            text: "TERBAYAR",
            value: "rp_bayar",
            divider: true,
            align: "right",
          },
          {
            text: "OUTSTAND",
            value: "rp_outstand",
            divider: true,
            align: "right",
          },
          { text: "OPSI", value: "opsi" },
        ],
        items: [],
        loading: false,
        search: "",
        noBukti: "",
        index: "",
      },
      filter: {
        jenis: "",
      },
      detail: {
        dialog: false,
        headers: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "NO. REF", value: "no_ref", divider: true },
          { text: "KET", value: "keterangan", divider: true },
          { text: "NAMA", value: "nama", divider: true },
          { text: "DEBIT", value: "debit", divider: true, align: "right" },
          { text: "KREDIT", value: "kredit", divider: true, align: "right" },
          { text: "SALDO", value: "saldo", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadDetail(no_bukti, kode_customer) {
      this.detail.items = [];
      this.detail.loading = true;
      this.detail.dialog = true;
      this.data.noBukti = no_bukti;
      await axios
        .post("penjualan/nota/outstanding/detail", {
          no_bukti: no_bukti,
          kode_customer: kode_customer,
        })
        .then((res) => {
          this.detail.items = res.data.data;
        });
      this.detail.loading = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("penjualan/nota/outstanding", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },
    async fetchData() {
      const res = await axios.post("master/customer");
      return res.data.data;
    },
  },
  computed: {
    totalTagihan() {
      let a = 0;
      this.data.items.forEach((element) => {
        a = a + element.rp_nota;
      });
      return a;
    },
    totalPembayaran() {
      let a = 0;
      this.data.items.forEach((element) => {
        a = a + element.rp_bayar;
      });
      return a;
    },
    totalOutstand() {
      let a = 0;
      this.data.items.forEach((element) => {
        a = a + element.rp_outstand;
      });
      return a;
    },
    countTagihan() {
      let a = 0;
      this.data.items.forEach(() => {
        a++;
      });
      return a;
    },
    countLunas() {
      let a = 0;
      this.data.items.forEach((el) => {
        if (el.rp_outstand <= 0) {
          a++;
        }
      });
      return a;
    },
    countBelumLunas() {
      let a = 0;
      this.data.items.forEach((el) => {
        if (el.rp_outstand > 0) {
          a++;
        }
      });
      return a;
    },
    filterData() {
      let data = "";
      if (this.filter.jenis == "lunas") {
        data = this.data.items.filter((el) => el.rp_outstand <= 0);
      } else if (this.filter.jenis == "belum") {
        data = this.data.items.filter((el) => el.rp_outstand > 0);
      } else {
        data = this.data.items;
      }

      return data;
    },
  },
};
</script>

<style></style>
