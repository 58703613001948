<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>MASTER BARANG</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col>
        <v-card outlined>
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-4">
              <v-btn
                color="success"
                rounded
                @click="
                  (addData.dialog = true), resetForm(), (staUpdate = false)
                "
                v-if="!staPilih"
                ><v-icon class="mr-2">mdi-plus-thick</v-icon>Tambah Data</v-btn
              >
              <!-- v-if="jenis != 'keluar'" -->
            </div>
            <!-- <div class="mr-2">
              <v-select
              :items="jenisBarang.items"
              v-model="jenisBarang.value"
              label="Jenis Barang"
              item-text="nm_jenis"
              dense
              outlined
              hide-details
              @change="loadData()"
            ></v-select>
            </div> -->

            <v-text-field
              name="name"
              label="Pencarian"
              outlined
              ref="inputSearch"
              dense
              hide-details
              clearable
              append-icon="mdi-magnify"
              v-model="data.search"
            ></v-text-field>

            
            <div v-if="jenis != 'keluar'">
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Barang"
                name="Data Barang.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div>
            <div>
              <v-btn color="primary" text @click="loadData()"
                >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    color="primary"
                    text
                    @click="
                      pilihBarang(
                        item.kode_bahan,
                        item.barcode,
                        item.nama_bahan,
                        item.masuk - item.keluar,
                        item.rp_distributor,
                        item.rp_staroutlet,
                        item.rp_grosir,
                        item.rp_retail,
                        item.merk,
                        item.ukuran,
                        item.unit
                      )
                    "
                    v-if="staPilih"
                    :disabled="
                      (item.masuk - item.keluar <= 0 ||
                        item.rp_distributor <= 0 ||
                        item.rp_staroutlet <= 0 ||
                        item.rp_grosir <= 0 ||
                        item.rp_retail <= 0) &&
                      jenis == 'keluar'
                    "
                    ><v-icon>mdi-check-bold</v-icon></v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="loadUpdate(item.kode_bahan)"
                    v-else
                    ><v-icon>mdi-pen</v-icon></v-btn
                  >
                </template>
                <template v-slot:[`item.stok`]="{ item }">
                  {{ formatNumber(item.masuk - item.keluar) }}
                </template>
                <template v-slot:[`item.rp_jual`]="{ item }">
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex justify-center">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                          small
                          rounded
                          text
                          ><v-icon color="primary"
                            >mdi-format-list-checkbox</v-icon
                          ></v-btn
                        >
                      </div>
                    </template>
                    <!-- Tooltip content -->
                    <div class="d-flex justify-end">
                      Distributor : Rp. {{ item.rp_distributor }}
                    </div>
                    <div class="d-flex justify-end">
                      Star-Outlet : Rp. {{ item.rp_staroutlet }}
                    </div>
                    <div class="d-flex justify-end">
                      Grosir : Rp. {{ item.rp_grosir }}
                    </div>
                    <div class="d-flex justify-end">
                      Retail : Rp. {{ item.rp_retail }}
                    </div>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addData.dialog"
      width="1100px"
      transition="dialog-transition"
    >
      <v-card class="pb-5">
        <v-card-title> Tambah Data </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form v-model="addData.isValid">
            <v-text-field
              v-model="addData.form.kodeBarang"
              label="(Otomatis) Kode Barang"
              dense
              outlined
              readonly
              placeholder="Otomatis"
            ></v-text-field>
            <v-text-field
              :rules="addData.rules.barcode"
              v-model="addData.form.barcode"
              label="Barcode Produk"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              :rules="addData.rules.namaBarang"
              v-model="addData.form.namaBarang"
              label="Nama Barang"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              :rules="addData.rules.ukuranBarang"
              v-model="addData.form.ukuranBarang"
              label="Ukuran Barang"
              dense
              outlined
            ></v-text-field>
            <div class="d-flex justify-space-around" v-if="!staUpdate">
              <v-text-field
                :value="addData.form.kodeJenis + ' - ' + addData.form.namaJenis"
                label="Jenis"
                readonly
                dense
                outlined
                class="mr-2"
              ></v-text-field>
              <v-btn
                @click="dialogJenisBarang = true"
                color="light-blue lighten-4"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </div>

            <div class="d-flex justify-space-around">
              <v-text-field
                v-model="addData.form.merk"
                label="Merk Baru"
                dense
                outlined
                class="mr-2"
              ></v-text-field>
              <v-autocomplete
                :items="merk.items"
                v-model="addData.form.merk"
                item-text="merk"
                item-value="merk"
                label="Pilih Merk"
                dense
                outlined
                @click="loadMerk()"
              ></v-autocomplete>
            </div>
            <div class="d-flex justify-space-around">
              <v-text-field
                :value="addData.form.kodeUnit + ' - ' + addData.form.namaUnit"
                label="Unit"
                dense
                outlined
                readonly
                class="mr-2"
              ></v-text-field>
              <v-btn
                @click="dialogUnitBarang = true"
                color="light-blue lighten-4"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </div>
            <v-row>
              <v-col cols="6">
                <div class="d-flex justify-space-around">
                  <v-text-field
                    v-model="addData.form.rp_distributor"
                    label="Harga Distributor"
                    dense
                    outlined
                    class="mr-2"
                  ></v-text-field>
                  <!-- <v-btn color="success" @click="updateRpjual('rp_distributor')"><v-icon>mdi-floppy</v-icon></v-btn> -->
                </div>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    v-model="addData.form.rp_staroutlet"
                    label="Harga Star-Outlet"
                    dense
                    outlined
                    class="mr-2"
                  ></v-text-field>
                  <!-- <v-btn color="success" @click="updateRpjual('rp_staroutlet')"><v-icon>mdi-floppy</v-icon></v-btn> -->
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex justify-space-around">
                  <v-text-field
                    v-model="addData.form.rp_grosir"
                    label="Harga Grosir"
                    dense
                    outlined
                    class="mr-2"
                  ></v-text-field>
                  <!-- <v-btn color="success" @click="updateRpjual('rp_grosir')"><v-icon>mdi-floppy</v-icon></v-btn> -->
                </div>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    v-model="addData.form.rp_retail"
                    label="Harga Retail"
                    dense
                    outlined
                    class="mr-2"
                  ></v-text-field>
                  <!-- <v-btn color="success" @click="updateRpjual('rp_retail')"><v-icon>mdi-floppy</v-icon></v-btn> -->
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <div class="d-flex justify-end mt-2">
              <v-btn
                color="success"
                v-if="!staUpdate"
                :disabled="!addData.isValid"
                @click="createData()"
                >SIMPAN<v-icon>mdi-floppy</v-icon></v-btn
              >
              <v-btn
                v-else
                color="warning"
                :disabled="!addData.isValid"
                @click="updateData()"
                >UPDATE<v-icon>mdi-floppy</v-icon></v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- JENIS -->
    <v-dialog
      v-model="dialogJenisBarang"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <jenis-barang :staPilih="true" v-on:jenis="getJenis"></jenis-barang>
      </v-card>
    </v-dialog>

    <!-- UNIT -->
    <v-dialog
      v-model="dialogUnitBarang"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <unit-view :staPilih="true" v-on:unit="getUnit"></unit-view>
      </v-card>
    </v-dialog>

    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import JenisBarang from "../Master/JenisBarang.vue";
import UnitView from "../Master/UnitView.vue";
export default {
  props: ["staPilih", "jenis"],
  components: {
    JenisBarang,
    UnitView,
  },
  data() {
    return {
      data: {
        headers: [
          { text: "KODE", value: "kode_bahan", divider: true },
          { text: "BARCODE", value: "barcode", divider: true },
          { text: "JENIS", value: "nm_jenis", divider: true },
          { text: "MERK", value: "merk", divider: true },
          { text: "NAMA", value: "nama_bahan", width: "25%", divider: true },
          { text: "UKURAN", value: "ukuran", divider: true },
          { text: "UNIT", value: "unit", divider: true },
          { text: "STOK", value: "stok", divider: true, align: "right" },
          {
            text: "HARGA JUAL",
            value: "rp_jual",
            divider: true,
            align: "right",
          },
          { text: "OPSI", value: "opsi", width: "5%" },
        ],

        items: [],
        loading: false,
        search: "",
      },

      addData: {
        dialog: false,
        isValid: false,
        form: {
          barcode: "",
          namaBarang: "",
          ukuranBarang: "",
          kodeJenis: "",
          namaJenis: "",
          kodeUnit: "",
          namaUnit: "",
          rp_jual: "",
          rp_distributor: "",
          rp_staroutlet: "",
          rp_grosir: "",
          rp_retail: "",
          merk: "",
        },
        rules: {
          barcode: [(v) => v != "" || "Barcode Barang Harus Diisi"],
          kodeJenis: [(v) => v != "" || "Pilih Jenis Barang"],
          namaBarang: [
            (v) => v != "" || "Nama Barang Harus Diisi",
            (v) => v.length > 5 || "Nama Barang Harus Lebih dari 5 Karakter",
          ],
          ukuranBarang: [(v) => v != "" || "Ukuran Barang Harus Diisi"],
          kodeUnit: [(v) => v != "" || "Pilih Unit/Satuan Barang"],
          merk: [(v) => v != "" || "Merk Harus Diisi"],
        },
        loading: false,
      },
      jenisBarang: {
        items: [],
      },
      merk: {
        staPilih: false,
        items: [],
      },
      dialogJenisBarang: false,
      dialogUnitBarang: false,
      child: "",
      staUpdate: false,
    };
  },
  mounted() {
    this.loadData();
    this.$refs.inputSearch.focus();
    this.loadMerk();
  },
  methods: {
    getJenis(data) {
      this.addData.form.kodeJenis = data.kd_jenis;
      this.addData.form.namaJenis = data.nm_jenis;
      this.dialogJenisBarang = false;
    },
    getUnit(data) {
      this.addData.form.idUnit = data.idUnit;
      this.addData.form.kodeUnit = data.kodeUnit;
      this.addData.form.namaUnit = data.namaUnit;
      this.dialogUnitBarang = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("master/barang", {
          // nm_jenis : this.jenisBarang.value.nm_jenis
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          console.log(err);
        });

      // LOAD JENIS BARANG
      await axios.post("master/jenisbarang").then((res) => {
        this.jenisBarang.items = res.data.data;
      });
      this.data.loading = false;
    },
    async loadMerk() {
      const res = await axios.post("master/merk");
      this.merk.items = res.data.data;
    },
    async createData() {
      this.addData.loading = true;
      await axios
        .post("master/barang/create", this.addData.form)
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.error, "error");
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          swal("Gagal", err, "error");
        });
      this.addData.loading = false;
    },
    resetForm() {
      this.addData.form = {
        barcode: "",
        namaBarang: "",
        ukuranBarang: "",
        kodeJenis: "",
        namaJenis: "",
        kodeUnit: "",
        namaUnit: "",
        merk: "",
        rp_distributor: "0",
        rp_staroutlet: "0",
        rp_grosir: "0",
        rp_retail: "0",
      };
    },
    async loadUpdate(kode) {
      this.resetForm();
      this.staUpdate = true;
      await axios
        .post("master/barang/bykode", {
          kode_bahan: kode,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.addData.dialog = true;
          this.addData.form.kodeBarang = res.data.data.kode_bahan;
          this.addData.form.barcode = res.data.data.barcode;
          this.addData.form.namaBarang = res.data.data.nama_bahan;
          this.addData.form.ukuranBarang = res.data.data.ukuran;
          this.addData.form.idUnit = res.data.data.id_unit;
          this.addData.form.kodeUnit = res.data.data.unit;
          this.addData.form.namaUnit = res.data.data.nama_unit;
          this.addData.form.rp_distributor = res.data.data.rp_distributor;
          this.addData.form.rp_staroutlet = res.data.data.rp_staroutlet;
          this.addData.form.rp_grosir = res.data.data.rp_grosir;
          this.addData.form.rp_retail = res.data.data.rp_retail;
          this.addData.form.merk = res.data.data.merk;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });
    },
    async updateData() {
      console.log(this.addData.form);
      await axios
        .post("master/barang/update", this.addData.form)
        .then((res) => {
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
          swal("Gagal", "Ada Kesalahan Server (404)", "error");
        });
    },
    // async updateRpDistributor() {
    //   await axios.post('master/barang/update/rpdistributor', {
    //     rp_distributor : this.addData.form.rp_distributor
    //   })
    // },
    // async updateRpStaroutlet() {
    //   await axios.post('master/barang/update/rpstaroutlet', {
    //     rp_staroutlet : this.addData.form.rp_staroutlet
    //   })
    // },
    // async updateRpGrosir() {
    //   await axios.post('master/barang/update/rpgrosir', {
    //     rp_grosir : this.addData.form.rp_grosir
    //   })
    // },
    // async updateRpRetail() {
    //   await axios.post('master/barang/update/rpretail', {
    //     rp_retail : this.addData.form.rp_retail
    //   })
    // },
    async fetchData() {
      const res = await axios.post("master/barang");
      return res.data.data;
    },
    pilihBarang(
      kode_bahan,
      barcode,
      nama_bahan,
      stok,
      rp_distributor,
      rp_staroutlet,
      rp_grosir,
      rp_retail,
      merk,
      ukuran,
      unit
    ) {
      this.$emit("barang", {
        kode_bahan: kode_bahan,
        barcode: barcode,
        nama_bahan: nama_bahan,
        stok: stok,
        rp_distributor: rp_distributor,
        rp_staroutlet: rp_staroutlet,
        rp_grosir: rp_grosir,
        rp_retail: rp_retail,
        merk: merk,
        ukuran: ukuran,
        unit: unit,
      });
    },
  },
};
</script>

<style></style>
