<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-around">
        <h2>LAPORAN PENJUALAN</h2>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="primary" text @click="loadData()"
            >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col class="d-flex justify-start">
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Awal"
                :value="formatDate(periode.tanggal1)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal1"
              @change="loadData()"
              @input="periode.pickerTanggal1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Akhir"
                :value="formatDate(periode.tanggal2)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal2"
              @change="loadData()"
              @input="periode.pickerTanggal2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <!-- TOTAL NOTA TAGIHAN -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-text-box</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalTagihan) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Tagihan</div>
          </v-card-text>
        </v-card>
      </v-col>
     
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div>
              <v-text-field
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
                class="mr-2"
              ></v-text-field>
            </div>
            <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn small value="semua"> Semua ({{ countTagihan }}) </v-btn>
                
              </v-btn-toggle>
            </div>
            <v-spacer></v-spacer>
            <!-- <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Return Penjualan"
                name="Data Return Penjualan.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="filterData"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.tanggal`]="{ item }">
                  {{ formatDate(item.tanggal) }}
                </template>
                <template v-slot:[`item.no_bukti`]="{ item }">
                  <strong> {{ item.no_bukti }}</strong>
                </template>
                <template v-slot:[`item.barang`]="{ item }">
                  <div>{{ item.kode_bahan }}</div>
                  <div><strong>{{ item.nama_bahan }}</strong> <v-chip outlined small>{{ item.unit }}</v-chip> </div>
                </template>
                <template v-slot:[`item.jumlah`]="{ item }">
                  {{ formatNumber(item.jumlah) }}
                </template>
                <template v-slot:[`item.rp_jual`]="{ item }">
                  {{ formatNumber(item.rp_jual) }}
                </template>
                <template v-slot:[`item.subtotal`]="{ item }">
                  {{ formatNumber(item.subtotal) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detail.dialog"
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2">
          <strong>{{ detail.nama }}</strong> ({{ detail.kodeCustomer }})
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card outlined>
            <v-data-table
              :headers="detail.headers"
              :items="detail.items"
              :loading="detail.loading"
              :search="detail.search"
              dense
            >
              <template v-slot:[`item.tanggal`]="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:[`item.debit`]="{ item }">
                {{ formatNumber(item.debit) }}
              </template>
              <template v-slot:[`item.kredit`]="{ item }">
                {{ formatNumber(item.kredit) }}
              </template>

              <template v-slot:[`item.saldo`]="{ item }">
                {{ formatNumber(item.saldo) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: ["staPilih"],
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "NAMA", value: "nama", divider: true },
          { text: "PEMBAYARAN", value: "pembayaran", divider: true },
          { text: "BARANG", value: "barang", divider: true },
          {
            text: "JUMLAH",
            value: "jumlah",
            divider: true,
            align: "right",
          },
          {
            text: "HARGA JUAL",
            value: "rp_jual",
            divider: true,
            align: "right",
          },
          {
            text: "HARGA DISKON",
            value: "diskon",
            divider: true,
            align: "right",
          },
          {
            text: "SUBTOTAL",
            value: "subtotal",
            divider: true,
            align: "right",
          },
        ],
        items: [],
        loading: false,
        search: "",
        noBukti: "",
        index: "",
      },
      filter: {
        jenis: "",
      },
      detail: {
        dialog: false,
        headers: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "NO. REF", value: "no_ref", divider: true },
          { text: "KET", value: "keterangan", divider: true },
          // { text: "NAMA", value: "nama", divider: true },
          { text: "DEBIT", value: "debit", divider: true, align: "right" },
          { text: "KREDIT", value: "kredit", divider: true, align: "right" },
          { text: "SALDO", value: "saldo", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
        kodeCustomer: "",
        nama: "",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadDetail(kode_customer, nama) {
      this.detail.items = [];
      this.detail.loading = true;
      this.detail.dialog = true;
      this.detail.kodeCustomer = kode_customer;
      this.detail.nama = nama;
      await axios
        .post("penjualan/piutang/detail", {
          kode_customer: kode_customer,
        })
        .then((res) => {
          this.detail.items = res.data.data;
        });
      this.detail.loading = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("laporan/penjualan/web", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },
  },
  computed: {
    totalTagihan() {
      let a = 0;
      this.data.items.forEach((element) => {
        a = a + element.subtotal;
      });
      return a;
    },
    countTagihan() {
      let a = 0;
      this.data.items.forEach(() => {
        a++;
      });
      return a;
    },
    filterData() {
      let data = "";
      if (this.filter.jenis == "lunas") {
        data = this.data.items.filter((el) => el.rp_outstand <= 0);
      } else if (this.filter.jenis == "belum") {
        data = this.data.items.filter((el) => el.rp_outstand > 0);
      } else {
        data = this.data.items;
      }

      return data;
    },
  },
};
</script>

<style></style>
